import React from 'react'
import ServiceLayOut from '../../../components/ServicesLayout'
import Layout from '../../../components/Layout'
import PageHeader from '../../../components/PageHeader'
import student_visa from "../../../assets/img/student_visa.png"
function StudentVisa() {
  return (
    <Layout>
      <div className="container-fluid bg-secondary px-0">
      <PageHeader title={"Student Visa"} pageName={"Student Visa"} />

        <div className="row g-0">
          <div className="col-lg-9 py-5 px-5">
            <div className='mb-5'>

            <img src={student_visa} className='w-100' style={{aspectRatio:"2/1"}}/>
            </div>
          <div>
              <ul className="text-gray">
                <li>Career Counseling</li>
                <li>Country Selection</li>
                <li>Course Selection</li>
                <li>University Selection</li>
                <li>Visa Guidance & Support</li>
                <li>Travel Arrangement</li>
                <li>Pre-departure orientation</li>
                <li>Admission Formalities</li>
                <li>SOP Assistance</li>
                <li>Education Loan</li>
                <li>Travel & Insurance Assistance</li>
              </ul>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Services to Students:</strong>
              </h5>
              <ul className="text-gray">
                <li>Appropriate selection of study curriculum</li>
                <li>
                  Coaching and guidance for IELTS, TOEFL, GRE, GMAT and SAT
                </li>
                <li>
                  Wide-ranging Universities/Colleges/Institutes according to
                  required study , admission options and preferred geographical
                  location/area
                </li>
                <li>Guidance and support for sponsorship and finance</li>
                <li>Applying to selected institutions and getting admission</li>
                <li>
                  Assistance with required documentation viz.SOP, References,
                  Letter of Recommendation, Legal Documents
                </li>

                <li>
                  Filling up the requisite visa and other forms online/offline
                </li>
                <li>
                  Comprehensive preparation for visa interview with proper
                  decorum
                </li>
              </ul>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Services to Skilled Workers:</strong>
              </h5>
              <ul className="text-gray">
                <li>
                  Structuring present work experience according to requirements
                  and availability of various countries
                </li>
                <li>Preparation for English tests (IELTS, TOEFL, CAEL)</li>
                <li>
                  Guidance on gaining proficiency in French, Spanish, German, or
                  other European languages
                </li>
                <li>Support for adaptability in foreign countries</li>
                <li>
                  Valuable guidance for obtaining Work Permit, Permanent
                  Residence, and Citizenship
                </li>
                <li>
                  Help with resume build-up, obtaining jobs, and setting up a
                  business
                </li>
                <li>
                  Comprehensive guidance on government-supported work, benefits,
                  and pensions
                </li>
              </ul>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Services to Partners, Children and Parents:</strong>
              </h5>
              <ul className="text-gray">
                <li>
                  Scrutiny of present socio-economic conditions, family
                  background, and previous travel history
                </li>
                <li>Methodical filing of application forms</li>
                <li>Training for viva interview</li>
                <li>Guidance on settlement of family members</li>
              </ul>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Post-Landing Assistance:</strong>
              </h5>
              <ul className="text-gray">
                <li>Airport pickup and transfer</li>
                <li>Arrangements for accommodation</li>
                <li>
                  Counseling and support for adapting to a new lifestyle and
                  culture
                </li>
                <li>
                  Guidance on obtaining official documents like insurance, bank
                  accounts, health card, driving license, etc.
                </li>
                <li>
                  Briefing on rules/regulations of
                  Universities/Colleges/Institutions
                </li>
                <li>Comprehensive help in job-seeking</li>
              </ul>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                We warmly welcome you at GO4N VISA SOLUTIONS……….!!!
              </h5>
              <ul className="text-gray">
                <li>
                  Known for overseas Education, Visitor, Business, PR
                  consultation work
                </li>
                <li>Authorized Representative of educational institutes</li>
                <li>
                  Customized combined package for admission to Bachelors &
                  Masters program provided
                </li>
                <li>
                  Free profile evaluation and suggestion of suitable
                  university/college options
                </li>
                <li>Assistance in Documentation for application</li>
                <li>Assistance in getting Scholarship</li>
                <li>Drafting of effective SOP and Resume</li>
                <li>Assistance for grant of Educational Loans</li>
                <li>
                  Visa Assistance, Financial Documentation, Mock Questioning,
                  Personal Interview briefing
                </li>
                <li>Spot assessment for selection of curriculum</li>
                <li>
                  Possibility of successful admission into PhD with funding
                </li>
                <li>
                  Pre-departure briefing assistance in accommodation, ticketing,
                  forex and insurance
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 py-5 service_layout" style={{ minHeight: "400px" }}>
            <ServiceLayOut />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default StudentVisa