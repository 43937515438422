import React from 'react'
import ServiceLayOut from '../../../components/ServicesLayout'
import Layout from '../../../components/Layout'
import PageHeader from '../../../components/PageHeader'
import vistor_visa from "../../../assets/img/vistor_visa.png"
function VisitorVisa() {
  return (
    <Layout>
      <div className="container-fluid bg-secondary px-0">
      <PageHeader title={"Visitor Visa"} pageName={"Visitor Visa"} />

        <div className="row g-0">
          <div className="col-lg-9 py-5 px-5">
          <div className='mb-5'>

<img src={vistor_visa} className='w-100' style={{aspectRatio:"2/1"}}/>
</div>
          <h5 className="mb-4 text-gray">
          We are offering the most excellent services to our esteemed clients for getting visitor visa for Australia, Canada, UK, USA, Gulf Countries and EU Countries to meet their kiths & kin. Our success ratio for grant of visitor visas to our clients is very high due to our experience and knowledge. The quality services are provided to our clients at very reasonable rates.

              </h5>
          </div>
          <div className="col-lg-3 py-5 service_layout" style={{ minHeight: "400px" }}>
            <ServiceLayOut />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VisitorVisa