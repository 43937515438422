// src/App.js
import React from 'react';
import { BrowserRouter , Route, Routes,  } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import "./App.css"
import "./scss/bootstrap.scss"
import InquiryForms from './pages/InquiryForm';
import StudyAbroad from './pages/StudyAbroad';
import OverseasPlacement from './pages/Services/OverseasPlacement';
import AirTicketCurrencyExchange from './pages/Services/AirTicketCurrencyExchange';
import StudentVisa from './pages/Services/StudentVisa';
import BusinessVisa from './pages/Services/BusinessVisa';
import VisitorVisa from './pages/Services/VisitorVisa';
import WhatWeDo from './pages/Services/WhatWeDo';
import Immegration from './pages/Immigration';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/inquiry-forms' element={<InquiryForms/>} />
        <Route path='/immigration' element={<Immegration/>} />
        <Route path='/study-abroad' element={<StudyAbroad />}/>
        {/* <Route path='/service/overseas-placement' element={<OverseasPlacement />}/> */}
        <Route path='/service/student-visa' element={<StudentVisa />}/>
        <Route path='/service/business-visa' element={<BusinessVisa />}/>
        <Route path='/service/visitor-visa' element={<VisitorVisa />}/>
        <Route path='/service/air-ticket&currency-exchange' element={<AirTicketCurrencyExchange />}/>
        <Route path='/service/what-we-do' element={<WhatWeDo />}/>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
