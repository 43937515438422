import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./inquireForm.css";
import axios from 'axios';
import { toast } from "react-toastify";

function InquiryFormSection() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    city: "",
    country: "",
    country_code: "",
    phone_number: "",
    immigration_country: "",
    inquiry_for: "",
    message: "",
    iso2: ""
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);  // New state for tracking submission

  useEffect(() => {
    if (formData) {
      // validateForm();
    }
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.phone_number) newErrors.phone_number = "Mobile No is required";
    if (!formData.immigration_country) newErrors.immigration_country = "Immegration Country is required";
    if (!formData.inquiry_for) newErrors.inquiry_for = "Inquiry For is required";
    if (!formData.message) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);  // Disable button and show loader
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}/api/add-inquiries`, formData);
        // const response = await axios.post(`http://localhost:8000/api/add-inquiries`, formData);
        toast.success(response.data.message);

        setFormData({
          name: "",
          email: "",
          city: "",
          country: "",
          country_code: "",
          phone_number: "",
          immigration_country: "",
          inquiry_for: "",
          message: "",
          iso2: ""
        });
      } catch (error) {
        if (error.response) {
          console.error("Validation Error Response:", error.response.data);
        } else {
          console.error("API Error:", error);
        }
        setErrors({
          apiError: error.response?.data?.message || "An error occurred. Please try again.",
        });
      } finally {
        setIsSubmitting(false);  // Re-enable button after submission is done
      }
    }
  };


  const inquiryOptions = {
    Canada: ["Select", "Student Visa", "PR Visa", "Visitor Visa", "Work Permit Visa", "Super Visa", "Student Dependent Visa"],
    default: ["Select", "Student Visa", "PR Visa", "Visitor Visa", "Work Permit Visa", "Student Dependent Visa"],
    Dubai: ["Select", "Visitor Visa"],
    Singapore: ["Select", "Visitor Visa"],
  };

  const getInquiryOptions = () => {
    if (formData.immigration_country === "Canada") {
      return inquiryOptions.Canada;
    } else if (["Dubai", "Singapore"].includes(formData.immigration_country)) {
      return inquiryOptions.Dubai;
    } else {
      return inquiryOptions.default;
    }
  };

  const handlePhoneChange = (value, data) => {
    const phoneWithoutCountryCode = value.slice(data.dialCode.length);
    setFormData({
      ...formData,
      phone_number: phoneWithoutCountryCode,
      country_code: data.dialCode,
      iso2: data.countryCode,
    });
  };

  return (
    <div className="container-fluid p-5  bg-secondary">
      <div className="row  g-5">
        {/* Contact Details Section */}
        {/* <div className="col-lg-6 col-md-12">
          <div className="p-4  mt-4">
            <h3 className="display-1 text-center text-primary mb-3">
              Contact Us
            </h3>
            <h5 className=" mb-3">
              We are here to help you with your visa inquiries. Reach out to us
              for any questions or assistance.
            </h5>

            <h5 className="mb-3">
              <strong>Our Contact Details</strong>
            </h5>
            <ul>
              <li>
                <strong>Email:</strong> info@visahelp.com
              </li>
              <li>
                <strong>Phone:</strong> +123-456-7890
              </li>
              <li>
                <strong>Address:</strong> 123 Main Street, City, Country
              </li>
            </ul>

            <h5 className="mb-3">
              <strong>Our Services</strong>
            </h5>
            <ul>
              <li>Student Visa Assistance</li>
              <li>PR Visa Guidance</li>
              <li>Work Permit Consultation</li>
              <li>Visitor Visa Help</li>
            </ul>

            <h5 className="mb-3"><strong>Office Hours</strong></h5>
            <ul>
              <li>Monday - Friday: 9:00 AM - 6:00 PM</li>
              <li>Saturday: 10:00 AM - 4:00 PM</li>
              <li>Sunday: Closed</li>
            </ul>
          </div>
        </div> */}

        {/* Inquiry Form Section */}
        <div className="col-lg-12 col-md-12">
          <div className="mt-1  d-flex flex-direction-column justify-content-center align-items-center">
            {/* <h2 className="text-center display-1 text-primary mb-4">Inquiry Form</h2> */}
            <div
              className="card p-4 shadow"
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                maxWidth: "500px",
              }}
            >
              <form onSubmit={handleSubmit}>
                {/* First Row */}
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.name ? "is-invalid" : ""
                        }`}
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="email" className="form-label">
                      Your E-mail
                    </label>
                    <input
                      type="email"
                      className={`form-control ${errors.email ? "is-invalid" : ""
                        }`}
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                </div>

                {/* Second Row */}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.city ? "is-invalid" : ""
                        }`}
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {errors.city && (
                      <div className="invalid-feedback">{errors.city}</div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.country ? "is-invalid" : ""
                        }`}
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                    {errors.country && (
                      <div className="invalid-feedback">{errors.country}</div>
                    )}
                  </div>
                </div>

                {/* Third Row */}
                {/* <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="phoneNo" className="form-label">
                      Phone No (Optional)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      name="phoneNo"
                      value={formData.phoneNo}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="mobileNo" className="form-label">
                      Mobile No
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.mobileNo ? "is-invalid" : ""
                      }`}
                      id="mobileNo"
                      name="mobileNo"
                      value={formData.mobileNo}
                      onChange={handleChange}
                    />
                    {errors.mobileNo && (
                      <div className="invalid-feedback">{errors.mobileNo}</div>
                    )}
                  </div>
                </div> */}

                <div className="row" style={{ marginBottom: '16px' }}>
                  <label htmlFor="phone_number" className="form-label">
                    Mobile No
                  </label>
                  <PhoneInput
                    country={formData.country_code || 'in'}
                    enableSearch={true}
                    value={formData.country_code + formData.phone_number}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: 'phone_number',
                      required: true,
                    }}
                    containerStyle={{
                      width: '96%',
                      marginTop: '0px',
                      backgroundColor: '#ffffff',
                      borderRadius: '8px',
                      padding: '0px',
                      marginLeft: '9px'
                    }}
                    inputStyle={{
                      width: '100%',
                      height: '44px',
                      border: 'none',
                      backgroundColor: 'transparent',
                      paddingLeft: '58px',
                      fontSize: '16px',
                      // border: '1px solid #D1D1D1',
                    }}
                    buttonStyle={{
                      border: 'none',
                      backgroundColor: 'lightgray',
                      borderRadius: '8px 0 0 8px',
                    }}
                    dropdownStyle={{
                      borderRadius: '8px',
                      overflowY: 'auto',
                    }}
                    searchPlaceholder="Search"
                    className={`form-control ${errors.phone_number ? "is-invalid" : ""
                      }`}
                  />
                  {errors.phone_number && (
                    <div className="invalid-feedback">{errors.phone_number}</div>
                  )}
                </div>

                {/* Fourth Row */}
                <div className="mb-3">
                  <label htmlFor="immigration_country" className="form-label">
                    Immegration Country
                  </label>
                  <select
                    className={`form-select ${errors.immigration_country ? "is-invalid" : ""
                      }`}
                    id="immigration_country"
                    name="immigration_country"
                    value={formData.immigration_country}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                    <option value="Australia">Australia</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Europe">Europe</option>
                    <option value="Dubai">Dubai</option>
                    <option value="Singapore">Singapore</option>
                  </select>
                  {errors.immigration_country && (
                    <div className="invalid-feedback">{errors.immigration_country}</div>
                  )}
                </div>

                {/* Fifth Row */}
                <div className="mb-3">
                  <label htmlFor="inquiry_for" className="form-label">
                    Inquiry For
                  </label>
                  <select
                    className={`form-select ${errors.inquiry_for ? "is-invalid" : ""
                      }`}
                    id="inquiry_for"
                    name="inquiry_for"
                    value={formData.inquiry_for}
                    onChange={handleChange}
                  >
                    {getInquiryOptions().map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {errors.inquiry_for && (
                    <div className="invalid-feedback">{errors.inquiry_for}</div>
                  )}
                </div>

                {/* Six Row */}
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className={`form-control ${errors.message ? "is-invalid" : ""
                      }`}
                    id="message"
                    name="message"
                    rows="3"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <div className="invalid-feedback">{errors.message}</div>
                  )}
                </div>
                <div className=" d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn_brown p-2 px-4"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                    disabled={isSubmitting}
                  >
                     {isSubmitting ? (
                    <span>
                      <i className="fa fa-spinner fa-spin me-2"></i> Submitting...
                    </span>
                  ) : (
                    "Submit"
                  )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InquiryFormSection;
