import React from 'react'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import StudyAbroadSection from '../../components/StudyAbroadSection'

function StudyAbroad() {
  return (
    <Layout>
    <PageHeader title={"Study Abroad"} pageName={"Study Abroad"} />
    <StudyAbroadSection />
       </Layout>
  )
}

export default StudyAbroad