import React from "react";
import { Link } from "react-router-dom";
import "./serviceslayout.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
function ServiceLayOut() {
  return (
    <div className="container-fluid pe-4">
      <div className="mb-5 bg-white p-4 shadow border_radius_10">
        <h5 className="mb-3 font-weight-bold text-center text-primary">Make An Appointment</h5>
        <p>
          Schedule a complimentary consultation with our migration experts
          today
        </p>
        <div className="px-2 d-flex justify-content-center">
          <Link to="/inquiry-forms" className="btn-brown">
            Meet Our Experts
          </Link>
        </div>
      </div>
      <div className="mb-5 bg-white p-4 shadow border_radius_10">
        <h5 className="mb-3 font-weight-bold text-primary text-center">Services</h5>
        <div className="py-2 ">
          {/* <div className="bg-secondary p-2 mb-1">
            <Link to={"/service/overseas-placement"} className="services_link">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-primary me-2"
              />{" "}
              Overseas Placement
            </Link>
          </div> */}
          <div className="bg-secondary p-2 mb-1">
            {" "}
            <Link to={"/service/student-visa"} className="services_link">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-primary me-2"
              />{" "}
              Student Visa
            </Link>
          </div>
          <div className="bg-secondary p-2 mb-1">
            {" "}
            <Link to={"/service/business-visa"} className="services_link">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-primary me-2"
              />{" "}
              Business Visa
            </Link>
          </div>
          <div className="bg-secondary p-2 mb-1">
            {" "}
            <Link to={"/service/visitor-visa"} className="services_link">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-primary me-2"
              />{" "}
              Visitor Visa
            </Link>
          </div>
          <div className="bg-secondary p-2 mb-1 d-flex align-items-start">
            <Link
              to={"/service/air-ticket&currency-exchange"}
              className="services_link d-flex align-items-start"
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-primary me-2 mt-1"
              />{" "}
              Air Ticket & Currency Exchange
            </Link>
          </div>
          <div className="bg-secondary p-2 mb-1">
            <Link to={"/service/what-we-do"} className="services_link">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-primary me-2"
              />{" "}
              What We Do
            </Link>
          </div>
        </div>
      </div>
      <div className="mb-5 bg-light p-4 shadow border_radius_10">
        <h5 className="mb-3 text-primary font-weight-bold text-center">
          {" "}
          How Can We Help You?
        </h5>
        <p >
          how can we help you? Please let us know if you have a question, want
          to leave a comment, or would like further information about Go4n Visa
          Services
        </p>
        <div className="px-2 d-flex justify-content-center">
          <Link to="/contact" className="btn-brown">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceLayOut;
