import { faArrowUp, faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";

const BackToTopButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setVisible(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    visible && (
      <button
        className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top d-flex align-items-center justify-content-center"
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faArrowUp} className="text-white"/>
      </button>
    )
  );
};

export default BackToTopButton;
