import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faChartPie, faChartLine, faChartArea, faBalanceScale, faHouseDamage } from '@fortawesome/free-solid-svg-icons';
import { faGlobe, faGraduationCap, faBriefcase, faPassport, faPlaneDeparture, faMoneyBillWave, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const services = [
  {
    icon: faGraduationCap,
    title: 'Student Visa',
    description: 'Simplifying your journey to study abroad by guiding you through the entire student visa process.',
    route: 'service/student-visa',
  },
  {
    icon: faBriefcase,
    title: 'Business Visa',
    description: 'Assisting entrepreneurs and professionals in obtaining business visas to expand their ventures internationally.',
    route: 'service/business-visa',
  },
  {
    icon: faPassport,
    title: 'Visitor Visa',
    description: 'We ensure a smooth process for obtaining visitor visas, so you can explore new countries stress-free.',
    route: 'service/visitor-visa',
  },
  {
    icon: faPlaneDeparture,
    title: 'Air Ticket & Currency Exchange',
    description: 'Offering competitive rates on international air tickets and currency exchange to make your travel more convenient.',
    route: 'service/air-ticket&currency-exchange',
  },
  {
    icon: faGlobe,
    title: 'Immigration',
    description: 'As a Canada immigration expert, I specialize in navigating the complexities of Canadian immigration laws and processes.',
    route: '/immigration',
  },
  {
    icon: faQuestionCircle,
    title: 'What We Do?',
    description: 'From visa processing to job placements and travel arrangements, we provide comprehensive support for your overseas journey.',
    route: 'service/what-we-do',
  },
];

const ServiceItem = ({ icon, title, description, handleServicesClick }) => (
  <div className="col-lg-4 col-md-6" onClick={handleServicesClick}>
    <div className="service-item shadow bg-secondary text-center px-5 pt-5 justify-content-start">
      <div
        className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4"
        style={{ width: '90px', height: '90px' }}
      >
        <FontAwesomeIcon icon={icon} size="2x" />
      </div>
      <h4 className="mb-3">{title}</h4>
      <p className="mb-0">{description}</p>
    </div>
  </div>
);

const ServicesSection = () => {
  const navigate = useNavigate();

  // Pass the route from the service item when clicked
  const handleServicesClick = (route) => {
    navigate(route);
  };

  return (
    <div className="container-fluid p-5 px-5" style={{cursor:'pointer'}}>
      <div className="text-center mx-auto mb-5" style={{ maxWidth: '600px' }}>
        <h1 className="display-5 mb-0">Our Services</h1>
        <hr className="w-25 mx-auto bg-primary" />
      </div>
      <div className="row g-5">
        {services.map((service, index) => (
          <ServiceItem
            key={index}
            icon={service.icon}
            title={service.title}
            description={service.description}
            handleServicesClick={() => handleServicesClick(service.route)} // Pass the service route to the click handler
          />
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
