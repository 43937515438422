import React from 'react'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import ImmegrationSection from '../../components/ImmigrationSection'

function Immegration() {
  return (
    <Layout>
 <PageHeader title={"Immigration"} pageName={"Immigration"} />
 <ImmegrationSection />
    </Layout>
  )
}

export default Immegration