import React from "react";
import ServiceLayOut from "../../../components/ServicesLayout";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import business_visa from "../../../assets/img/business_visa.png"
function BusinessVisa() {
  return (
    <Layout>
      <div className="container-fluid bg-secondary px-0">
        <PageHeader title={"Business Visa"} pageName={"Business Visa"} />

        <div className="row g-0">
          <div className="col-lg-9 py-5 px-5">
          <div className='mb-5'>

<img src={business_visa} className='w-100' style={{aspectRatio:"2/1"}}/>
</div>
            <h5 className="mb-4 text-gray">
            We also provide our services to businessmen and professionals for
              getting business visas for Australia, Canada, China, UK, USA, Gulf
              Countries and EU Countries to attend business meeting, conference,
              exhibition, workshop, training etc. at affordable process.
              </h5>
          </div>
          <div className="col-lg-3 py-5 service_layout" style={{ minHeight: "400px" }}>
            <ServiceLayOut />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BusinessVisa;
