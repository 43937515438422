import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelopeOpen,
  faGlobe,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import group_124 from "../../assets/img/group_124.png";
import india_flag from "../../assets/icon/india_flag.png";
import canada_flag from "../../assets/icon/canada_flag.png";
import "./footer.css"
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-dark text-secondary p-5">
        <div className="row g-3 d-flex space_between">
          <div className="col-lg-2 col-md-6">
            <h3 className="text-white mb-3">Quick Links</h3>
            <div className="d-flex flex-column justify-content-start">
              <Link className="text-secondary mb-2 font_1rem" to="/">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Home
              </Link>
              <Link className="text-secondary mb-2 font_1rem" to="/about">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                About Us
              </Link>

              <Link
                className="text-secondary mb-2 d-flex align-items-start font_1rem"
                to="/"
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Our Service
              </Link>

              <Link className="text-secondary mb-2 font_1rem" to="/inquiry-forms">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Inquiry Forms
              </Link>

              <Link className="text-secondary mb-2 font_1rem" to="/immigration">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Immigration
              </Link>

              <Link className="text-secondary mb-2" to="/study-abroad">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Study Abroad
              </Link>
              <Link className="text-secondary" to="/contact">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Contact Us
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="text-white mb-3">Our Service</h3>
            <div className="d-flex flex-column justify-content-start">
              {/* <a className="text-secondary mb-2 font_1rem" href="#">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Overseas Placement
              </a> */}
              <Link className="text-secondary mb-2 font_1rem" to={"/service/student-visa"}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Student Visa
              </Link>

              <Link
                className="text-secondary mb-2 d-flex align-items-start font_1rem"
                to={"/service/business-visa"}
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Business Visa
              </Link>

              <Link className="text-secondary mb-2 font_1rem" to={"/service/visitor-visa"}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Visitor Visa
              </Link>

              <Link className="text-secondary mb-2" to={"/service/air-ticket&currency-exchange"}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Air Ticket & Currency Exchange
              </Link>
              <Link className="text-secondary mb-2" to={"/service/what-we-do"}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                What We Do
              </Link>
              <Link className="text-secondary" to={"https://go4nvisa.com/admin"}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-primary me-2"
                />
                Staff Login
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <h3 className="text-white mb-3">Our Locations</h3>
            <div className="row g-0 d-flex space-between">
              <div className="col-lg-6 col-md-6">
                <div className="mb-3 ">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src={india_flag}
                      alt="india"
                      className="flag_icon me-3"
                    />
                    <h5 className="text-white mb-0">
                      <strong>India</strong>
                    </h5>
                  </div>
                  <div className="d-flex align-items-start">
                    {/* <h6 className="mb-3 text-white"><strong>India Headquartor</strong></h6> */}
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-primary me-2 mt-1"
                    />

                    <span className="font_1rem">
                      {" "}
                      204, Sangath Mall-1
                      <br></br> Opp. GTU University
                      <br></br>
                      Motera, Ahmedabad
                      <br></br>
                      Gujarat 380005
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-start">
                    {/* <p className="mb-0 font_1rem"> */}
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="text-primary me-2"
                      />
                      <span className="font_1rem">
                        +91-9825060756
                        <br /> 
                        +91-9033435343
                      </span>
                    {/* </p> */}
                  </div>
                  <p className="mb-2">
                    <FontAwesomeIcon
                      icon={faEnvelopeOpen}
                      className="text-primary me-2"
                    />
                   go4nvisa@gmail.com
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="mb-3">
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src={canada_flag}
                      alt="canada"
                      className="flag_icon me-3"
                    />
                    <h5 className="text-white mb-0">
                      <strong>Canada</strong>
                    </h5>
                  </div>
                  <div className="d-flex align-items-start">
                    {/* <h6 className="mb-3 text-white"><strong>Canada Head Office</strong></h6>   */}
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-primary me-2 mt-1"
                    />
                    <span className="font_1rem">
                      {" "}
                      4-405 Pandora Ave E <br></br>Winnipeg, Manitoba
                      <br></br>Canada R2C 5P3
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-start">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="text-primary me-2"
                    />
                    <span>
                      +1-204-698-9555
                      <br/>
                      +1-431-345-2611
                    </span>
                  </div>
                  <p className="mb-2">
                    <FontAwesomeIcon
                      icon={faEnvelopeOpen}
                      className="text-primary me-2"
                    />
                    canadago4n@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid bg-dark text-secondary text-center border-top py-4 px-5"
        style={{ borderColor: "rgba(256, 256, 256, .1) !important" }}
      >
        <div className="copy_write_div">
        <p className="m-0 d-flex align-items-center justify-content-center">
          Powered By &nbsp;
          <a
            className="text-primary border-right border_bottem_red"
            href="https://www.spectusinfotech.com/"
            target="_blank"
          >
            Spectus Infotech
          </a>
          </p>
          <div className="border"></div>
          <p className="m-0 d-flex align-items-center justify-content-center">&copy; {(new Date().getFullYear())} By Go4n Visa Solutions.</p>
        </div>
       
          
          
      
      </div>
    </>
  );
};

export default Footer;
