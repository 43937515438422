import React from 'react'
import PageHeader from '../../components/PageHeader'
import Layout from '../../components/Layout'
import AboutSection from '../../components/AboutSection'
import TeamSection from '../../components/TeamSection'
import HeaderCarousel from '../../components/HeaderCarousel'

function About() {
  return (
    <Layout>
    <PageHeader title={"About Us"} pageName={"About"}/>
    <AboutSection />
    {/* <TeamSection /> */}
    {/* <HeaderCarousel /> */}
    </Layout>
  )
}

export default About