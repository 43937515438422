import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCubes,
  faPercent,
  faAward,
  faSmileBeam,
  faUserTie,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import featureImage from "../../assets/img/feature.jpg";
const features = [
  {
    icon: faCubes,
    title: "Best In Industry",
    description:
      "Go4n Visa offers top-tier visa immigration services with expert consultants and seamless processing for efficient, tailored solutions.",
  },

  {
    icon: faUserTie,
    title: "Professional Advisors",
    description:
      "Trusted expertise and personalized guidance for your visa and immigration needs.",
  },
  {
    icon: faSmileBeam,
    title: "100% Happy Client",
    description:
      " Ensuring satisfaction with our dedicated visa and immigration services tailored to your specific requirements.",
  },
];

const FeatureItem = ({ icon, title, description }) => (
  <>
    <div
      className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3"
      style={{ width: "60px", height: "60px", margin: "0px auto" }}
    >
      <FontAwesomeIcon icon={icon} className="fs-4 text-white" />
    </div>
    <h4 style={{ textAlign: "center" }}>{title}</h4>
    <p className="mb-0" style={{ textAlign: "center" }}>
      {description}
    </p>
  </>
);

const FeaturesSection = () => {
  return (
    <div className="container-fluid bg-secondary py-6 px-5 mt-4">
      <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
        <h1 className="display-5 mb-0">Why Choose Us?</h1>
        <hr className="w-25 mx-auto bg-primary" />
      </div>
      <div className="row g-3 d-flex space_between">
        {features.map((feature, index) => (
          <div className="col-lg-4  py-2 px-4">
            <div className="bg-white p-5 px-4 shadow" style={{minHeight:"310px"}}>
            <FeatureItem key={index} {...feature} />
            </div>
          </div>
        ))}
        {/* <div className="col-lg-4">
          <div className="row g-5 px-5">
            {features.slice(1, 2).map((feature, index) => (
               <div className="col-12 bg-white p-5 px-4">
              <FeatureItem key={index} {...feature} />
              </div>
            ))}
          </div>
        </div> */}
        {/* <div className="col-lg-4">
          <div className="d-block bg-primary h-100 text-center">
            <img className="img-fluid" src={featureImage} alt="Feature" />
            <div className="p-4">
              <p className="text-white mb-4">
                Clita nonumy sanctus nonumy et clita tempor, et sea amet ut et sadipscing rebum amet takimata amet, sed accusam eos eos dolores dolore et.
              </p>
              <a href="/" className="btn btn-light py-md-3 px-md-5 rounded-pill mb-2">
                Learn More
              </a>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-4 ">
          <div className="row g-5 px-5">
            {features.slice(2).map((feature, index) => (
              <div className="col-12 bg-white p-5 px-4">
              <FeatureItem key={index} {...feature} />
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FeaturesSection;
