import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faChartLine,
  faBalanceScale,
} from "@fortawesome/free-solid-svg-icons";
import group_92 from "../../assets/img/group_92.png";
import group_93 from "../../assets/img/group_93.png";
import visa_3 from "../../assets/img/visa_3.png"
import mission from "../../assets/img/misson.png"
const services = [
  {
    icon: faUserTie,
    title: "Business Planning",
    description:
      "Tempor erat elitr rebum at clita. Diam dolor ipsum amet eos erat ipsum lorem et sit sed stet lorem sit clita duo.",
  },
  {
    icon: faChartLine,
    title: "Financial Analysis",
    description:
      "Tempor erat elitr rebum at clita. Diam dolor ipsum amet eos erat ipsum lorem et sit sed stet lorem sit clita duo.",
  },
  {
    icon: faBalanceScale,
    title: "Legal Advisory",
    description:
      "Tempor erat elitr rebum at clita. Diam dolor ipsum amet eos erat ipsum lorem et sit sed stet lorem sit clita duo.",
  },
];

const ServiceCard = ({ icon, title, description }) => (
  <div className="d-flex text-white mb-5">
    <div
      className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4"
      style={{ width: "60px", height: "60px" }}
    >
      <FontAwesomeIcon icon={icon} className="fs-4" />
    </div>
    <div className="ps-4">
      <h3>{title}</h3>
      <p className="mb-0">{description}</p>
    </div>
  </div>
);

const AboutSection = () => {
  return (
    <div>
      <div className="container-fluid bg-secondary p-0">
        <div className="row g-0">
          <div className="col-lg-6 py-6 px-5">
            <h1 className="display-5 mb-4">
              <span className="text-primary">About Us</span>
            </h1>
            <p className="mb-4 text-justify">
              GO4N VISA SOLUTIONS established in 2001 with an aim to provide
              value-added student and immigration consultancy services to its
              clients. The company is DIC/AHD/EM/PART-1/8972 Government
              registered firm. We are one of the best established consultancy
              companies in the state of Gujarat. The company is a combination of
              sincere and hard efforts with a very noble vision to provide
              people a better future in their life. GO4N VISA SOLUTIONS offers
              an integrated mould of services for people who wants to re-birth
              their career. We provide consultancy services in Student Visa,
              Visitor Visa, Schengen Visa, Work Permit and Immigration for
              different countries viz. Australia, Canada, UK, USA, New Zealand,
              Germany, Denmark, Sweden, Cyprus, Ireland, China, Singapore,
              France, Italy, Switzerland, Malaysia, Russia, Philippines, Middle
              East Countries and EU Countries.
            </p>
            <p className="mb-4 text-justify">
              We mainly focus on the requirement of our clients under several
              categories in order to provide a wider and unbiased perception;
              due to the uniqueness of goals of each individual. Over the past
              14 years, we have supported large numbers of clients with their
              visa applications. We have successfully facilitated more than
              1,000 students to get admission and entry clearance to various
              countries. We have also rendered our quality services to get Work
              Permits and Permanent Residence and thereby pursue profitable
              career opportunities in developed countries. Many of our clients
              have got Tourist Visas owing to our efforts to visit the countries
              of their choice.
            </p>
          </div>
          <div
            className="col-lg-6"
            style={{
              backgroundImage: `url(${group_92})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            {/* <div className="h-100 d-flex flex-column justify-content-center  p-5">
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            ))}
          </div> */}
          </div>
        </div>
      </div>
      <div className="container-fluid  p-0">
        <div className="row g-0">
          <div
            className="col-lg-6"
            style={{
              backgroundImage: `url(${group_93})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="col-lg-6 py-6 px-5">
            {/* <h1 className="display-5 mb-4">
              <span className="text-primary">About Us</span>
            </h1> */}
            <p className="mb-4 text-justify">
              GO4N VISA SOLUTIONS, a one- stop solution for the aspirants
              planning to study, migrate or visit overseas. We are providing
              result-oriented and client-centric services for Foreign Education,
              Immigration, and Visa for the above mentioned countries. We invite
              all aspirants who are determined to pursue a distinctive
              opportunity to enjoy a higher living standard and greater fortune
              and desire to progress career prospects and to achieve your
              family's future.
            </p>
            <p className="mb-4 text-justify">
              We provide all services under one-roof from passport application
              to post landing services. We believe in transparency, high
              standard of ethics and provide quality services at most reasonable
              service charges. We are instrumental to fulfill the dream of our
              many applicants for getting their visas with our proven methods of
              application processing.
            </p>
            <p className="mb-4 text-justify">
              We have a dedicated team comprises of expert professionals with
              proven experience in immigration and education who are always
              equipped to motivated to shape better prospect of our clients.
              Also, we are authorized associate for many overseas colleges and
              universities across the globe.
            </p>
            <p className="mb-4 text-justify">
              We are also providing coaching/counseling to our prospective
              students/clients for IELTS, TOFEL, GMAT, GRE, CAEL, English
              Speaking and SAT as also Interview Preparation, Personality
              Development, and Skill Development
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-secondary p-0 mb-4">
        <div className="row g-0">
          <div className="col-lg-6 py-6 px-5">
            <h1 className="display-5 mb-4">
              <span className="text-primary">Our Vision</span>
            </h1>
            <p className="mb-4 text-justify">
              Go4n Visa Solutions is working with the clear vision of providing
              best customer service in a timely manner with most efficient way.
              The basic goal of the company is to understand the whole
              background of the clients and advise for Student Visa /
              Immigration / Work Permit and Visitor Visa accordingly because
              maximum satisfaction of our clients is our motto. Go4n Visa
              Solutions use to establish very good relationship with clients and
              consider the clients as family members. Go4n Visa Solutions
              believe in 10:1 ratio for business, i.e. company wants to see the
              growth of clients 10 times more than the company itself.
            </p>
            <p className="mb-4 text-justify">
              We are determined to work intimately with our clients to build
              confidence and bring best solutions to their immigration
              objectives with adherence to high professional ethics and privacy.
              We aspire to strengthen the scope of our valued services by
              offering an integrated approach for creating innovative outlook
              for our clients.
            </p>
          </div>
          <div
            className="col-lg-6"
            style={{
              backgroundImage: `url(${visa_3})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>
      <div className="container-fluid  p-0" style={{marginBottom:'20px'}}>
        <div className="row g-0">
          <div
            className="col-lg-6"
            style={{
              backgroundImage: `url(${mission})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="col-lg-6 py-6 px-5">
            <h1 className="display-5 mb-4">
              <span className="text-primary">Our Mission</span>
            </h1>
            <p className="mb-4 text-justify">
            Our Mission is to offer concise, clear and transparent service exceed ones anticipation and accomplish their dreams to begin their new and exciting life in their country of preference. We bestow our efforts to provide services at most reasonable charges through our proficient and dedicated staff. We are also committed to extend candid, competent and result-oriented services through proper counseling and representing our clients in overseas in a wide range of immigration matters. Our center of attention is to extend all support, building trustworthy relationships and promoting success.
            </p>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
