// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSmile } from "@fortawesome/free-solid-svg-icons";
// import web_logo from "../../assets/img/web_logo.png";

// const Header = () => {
//   const [isSticky, setIsSticky] = useState(false);
//   const [showBackToTop, setShowBackToTop] = useState(false);
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [isCollapsed, setIsCollapsed] = useState(true); // To handle the navbar collapse on mobile screens

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsSticky(window.scrollY > 40);
//       setShowBackToTop(window.scrollY > 200); // Show back-to-top button after 200px of scroll
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const handleBackToTopClick = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   const toggleNavbar = () => {
//     setIsCollapsed(!isCollapsed); // Toggling navbar for mobile view
//   };

//   return (
//     <>
//       <nav
//         className={`navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0 ${
//           isSticky ? "sticky-top" : ""
//         }`}
//       >
//         <a to="/" className="navbar-brand p-0">
//           <img src={web_logo} alt="web_logo" className="web_logo_width" />
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           onClick={toggleNavbar}
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div
//           className={`collapse navbar-collapse ${!isCollapsed ? "show" : ""}`}
//           id="navbarCollapse"
//         >
//           <div className="navbar-nav ms-auto py-0 me-n3">
//             <a to="/" className="nav-item nav-link active">
//               Home
//             </a>
//             <a to="/about" className="nav-item nav-link">
//               About
//             </a>
//             <a to="/service" className="nav-item nav-link">
//               Service
//             </a>
//             <div
//               className="nav-item dropdown"
//               onMouseEnter={() => setDropdownOpen(true)}
//               onMouseLeave={() => setDropdownOpen(false)}
//             >
//               <a
//                 to="#"
//                 className="nav-link dropdown-toggle"
//                 data-bs-toggle="dropdown"
//               >
//                 Pages
//               </a>
//               <div
//                 className={`dropdown-menu m-0 ${dropdownOpen ? "show" : ""}`}
//               >
//                 <a to="/blog" className="dropdown-item">
//                   Blog Grid
//                 </a>
//                 <a to="/detail" className="dropdown-item">
//                   Blog Detail
//                 </a>
//                 <a to="/feature" className="dropdown-item">
//                   Features
//                 </a>
//                 <a to="/quote" className="dropdown-item">
//                   Quote Form
//                 </a>
//                 <a to="/team" className="dropdown-item">
//                   The Team
//                 </a>
//                 <a to="/testimonial" className="dropdown-item">
//                   Testimonial
//                 </a>
//               </div>
//             </div>
//             <a to="/contact" className="nav-item nav-link">
//               Contact
//             </a>
//           </div>
//         </div>
//       </nav>

//       {/* Back to Top Button */}
//       {showBackToTop && (
//         <button className="back-to-top" onClick={handleBackToTopClick}>
//           <FontAwesomeIcon icon={faSmile} />
//         </button>
//       )}
//     </>
//   );
// };

// export default Header;

import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import web_logo from "../../assets/img/web_logo.png";
import { Link, useLocation } from "react-router-dom"; // Ensure you're using react-router
import "./header.css";
import RightImg from "../../assets/img/right.png"
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 40);
      setShowBackToTop(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBackToTopClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const handleDropdownClick = () => {
    setDropdownOpen((prev) => !prev); // Toggle the dropdown
  };

  const handleItemClick = () => {
    setDropdownOpen(false); // Close the dropdown when an item is clicked
  };

  // Effect to handle clicks outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close the dropdown
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); //
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg  header_bg_color navbar-light shadow-sm px-4 py-3 py-lg-0 ${isSticky ? "sticky-top" : ""
          }`}
      >
        <Link to="/" className="navbar-brand p-0">
          <img src={web_logo} alt="web_logo" className="web_logo_width" />
        </Link>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${!isCollapsed ? "show" : ""}`}
          id="navbarCollapse"
        >
          <div className="navbar-nav py-0 me-n3 px-3" style={{justifyContent:'space-between', flexGrow: 2}}>
            <div className="menu-items-nav">
              <Link
                to="/"
                className={`font_weight_700 nav-item nav-link ${getActiveClass(
                  "/"
                )}`}
              >
                Home
              </Link>
              <Link
                to="/about"
                className={`font_weight_700 nav-item nav-link ${getActiveClass(
                  "/about"
                )}`}
              >
                About
              </Link>

              {/* Our Service Dropdown */}
              {/* <div className="nav-item dropdown" onMouseEnter={() => toggleDropdown('service')} onMouseLeave={() => toggleDropdown('service')}>
              <Link to="#" className="font_weight_700 nav-link dropdown-toggle" data-bs-toggle="dropdown">
                Our Service
              </Link>
              <div className={`dropdown-menu m-0 ${dropdownOpen.service ? "show" : ""}`}>
                <Link to="#" className="dropdown-item">Overseas Placement</Link>
                <Link to="#" className="dropdown-item">Student Visa</Link>
                <Link to="#" className="dropdown-item">Business Visa</Link>
                <Link to="#" className="dropdown-item">Visitor Visa</Link>
                <Link to="#" className="dropdown-item">Air Ticket & Currency Exchange</Link>
                <Link to="#" className="dropdown-item">What We Do</Link>
              </div>
            </div> */}
              {/* <div className="topbar_dropdown">
              <span className="header_top_dropdown">
     
                <Link
                  to="#"
                  className=" dp_title font_weight_700 nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Our Service
                </Link>
              </span>
              <ul className="header_slide">
                <li>Overseas Placement</li>
                <li>Student Visa</li>
                <li>Business Visa</li>
                <li>Visitor Visa</li>
                <li>Air Ticket & Currency Exchange </li>
                <li>What We Do</li>
              </ul>
            </div> */}
              {/* <div
              className="topbar_dropdown"
              onMouseLeave={() => setIsOpen(false)}
              onMouseDown={() => setIsOpen(true)}
            >
              <span
                className="header_top_dropdown"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Link
                  to="#"
                  className="dp_title font_weight_700 nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Our Service
                </Link>
              </span>
              <ul className={`header_slide ${isOpen ? "open" : ""}`}>
                <li onClick={handleItemClick}>Overseas Placement</li>
                <li onClick={handleItemClick}>Student Visa</li>
                <li onClick={handleItemClick}>Business Visa</li>
                <li onClick={handleItemClick}>Visitor Visa</li>
                <li onClick={handleItemClick}>
                  Air Ticket & Currency Exchange
                </li>
                <li onClick={handleItemClick}>What We Do</li>
              </ul>
            </div> */}
              {/* <div className="header_topbar_dropdown">
                <span className="header_top_dropdown" onClick={handleDropdownClick}>
                  <Link
                    to="#"
                    className="dp_title font_weight_700 nav-link dropdown-toggle"
                    // data-bs-toggle="dropdown"
                  >
                    Our Service
                  </Link>
                </span>
                <ul className={`header_topbar_slide ${dropdownOpen ? "open" : ""}`}>
                <li onClick={handleItemClick}>Overseas Placement</li>
                  <li onClick={handleItemClick}>Student Visa</li>
                  <li onClick={handleItemClick}>Business Visa</li>
                  <li onClick={handleItemClick}>Visitor Visa</li>
                  <li onClick={handleItemClick}>
                    Air Ticket & Currency Exchange
                  </li>
                  <li onClick={handleItemClick}>What We Do</li>
                </ul>
              </div> */}
              <div className="header_topbar_dropdown" ref={dropdownRef}>
                <span
                  className="header_top_dropdown"
                  onClick={handleDropdownClick}
                >
                  <Link
                    to="#"
                    className="dp_title font_weight_700 nav-link dropdown-toggle"
                  >
                    Our Service
                  </Link>
                </span>
                <ul
                  className={`header_topbar_slide ${dropdownOpen ? "open" : ""}`}
                >
                  {/* <li>
                  <Link
                    to={"/service/overseas-placement"}
                    onClick={handleItemClick}
                    className="link"
                  >
                    Overseas Placement
                  </Link>
                </li> */}
                  <li>
                    {" "}
                    <Link to={"/service/student-visa"} onClick={handleItemClick} className="link">
                      Student Visa
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={"/service/business-visa"} onClick={handleItemClick} className="link">
                      Business Visa
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={"/service/visitor-visa"} onClick={handleItemClick} className="link">
                      Visitor Visa
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/service/air-ticket&currency-exchange"}
                      onClick={handleItemClick}
                      className="link"
                    >
                      Air Ticket & Currency Exchange
                    </Link>
                  </li>
                  <li>
                    <Link to={"/service/what-we-do"} onClick={handleItemClick} className="link">
                      What We Do
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                to="/immigration"
                className={`font_weight_700 nav-item nav-link ${getActiveClass(
                  "/immigration"
                )}`}
              >
                Immigration
              </Link>
              <Link
                to="/inquiry-forms"
                className={`font_weight_700 nav-item nav-link ${getActiveClass(
                  "/inquiry-forms"
                )}`}
              >
                Inquiry Form
              </Link>
              <Link
                to="/study-abroad"
                className={`font_weight_700 nav-item nav-link ${getActiveClass(
                  "/study-abroad"
                )}`}
              >
                Study Abroad
              </Link>

              <Link
                to="/contact"
                className={`font_weight_700 nav-item nav-link ${getActiveClass(
                  "/contact"
                )}`}
              >
                {/* <button className="btn_brown header_btn">Contact Us</button> */}
                Contact Us
              </Link>
            </div>

            <div className="d-none d-md-none d-lg-flex right-image">
              <img src={RightImg} alt="right-img" style={{ height: '40px' }} />
            </div>
          </div>
        </div>
      </nav>

      {/* Back to Top Button */}
      {showBackToTop && (
        <button className="back-to-top" onClick={handleBackToTopClick}>
          <FontAwesomeIcon icon={faSmile} />
        </button>
      )}
    </>
  );
};

export default Header;
