import React from 'react';
import "./immigration.css";
import Logo from "../../assets/img/web_logo.png";
import exploreOpp from "../../assets/img/explore-opp.png";
import RCIC from "../../assets/img/RCIC.svg";
import newMap from "../../assets/img/newmap.png";
import img1 from "../../assets/img/slider-img1.svg";
import img2 from "../../assets/img/slider-img2.svg";
import img3 from "../../assets/img/slider-img3.svg";
import img4 from "../../assets/img/slider-img4.svg";
import img5 from "../../assets/img/slider-img5.svg";
import img6 from "../../assets/img/slider-img6.svg";
import img7 from "../../assets/img/slider-img7.svg";
import img8 from "../../assets/img/slider-img8.svg";
import canadaImmegration from "../../assets/img/canada-immegation.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import GoogleReivewSection from '../GoogleReviewSection';
import Slider from 'react-slick';

const ImmegrationSection = () => {
    const navigate = useNavigate();

    const handleInqurire = () => {
        navigate("/inquiry-forms");
    }

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
                breakpoint: 1024, // Screens smaller than 1024px
                settings: {
                    slidesToShow: 3, // Show 2 slides on medium-sized screens
                },
            },
            {
                breakpoint: 768, // Screens smaller than 768px
                settings: {
                    slidesToShow: 3, // Show 1 slide on small screens
                },
            },
            {
                breakpoint: 480, // Screens smaller than 480px
                settings: {
                    slidesToShow: 1, // Show 1 slide on extra-small screens
                    arrows: false, // Optionally hide arrows for mobile view
                },
            },
        ],
    };


    const data = [
        {
            img: img1,
        },
        {
            img: img2,
        },
        {
            img: img3,
        },
        {
            img: img4,
        },
        {
            img: img5,
        },
        {
            img: img6,
        },
        {
            img: img7,
        },
        {
            img: img8,
        },
    ];

    return (
        <>
            <div className='main-container'>
                <div class="container">
                    <div className='row'>
                        <div class="col-lg-7">
                            <div class="main_new_left_banner_content">
                                <img class="map_new" src={newMap} alt="" />
                                <span class="section-title red">Canada Immigration Service</span>
                                <h1>Canada Immigration Expert</h1>
                                <p></p>
                                <div class="main_facts_about_countries">
                                    <div class="row">
                                        <div class="col-5 mt-3">
                                            <div class="box" style={{ transform: 'none' }}>
                                                <div class="fact-img immigration">
                                                    {/* <img src="https://www.angeledunext.com/assets/frontend/images/Angel Visa &amp; Immigration Logo.svg" alt=""/> */}
                                                    <img src={Logo} alt='logo' />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5 mt-3">
                                            <div class="box" style={{ transform: 'none' }}>
                                                <div class="fact-img immigration">
                                                    <img src={RCIC} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-3 text-start">
                                            <p class="banner-tag section-title red"><em>- Mukesh Patel</em></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5  d-lg-block d-md-none d-none">
                            <div class="main_new_right_banner_content">
                                <img src={canadaImmegration} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='expolre-opp'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-4'>
                            <img class="main_service_img d-sm-block d-block" src={exploreOpp} alt="" />
                        </div>
                        <div className='col-lg-7'>
                            <div class="section-title red mb-2">
                                <span>Explore Opportunities</span>
                            </div>
                            <h2 class="service_details_title mb-4">Explore Endless Opportunities, Creating your Future Here</h2>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                High standards of living: Canada ensures a comfortable lifestyle with quality amenities and services for residents.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Free healthcare: Accessible medical services without financial burden promote well-being and security for all.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Clean, safe environment: Beautiful landscapes and low crime rates create a healthy and secure atmosphere.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Robust economy: Opportunities thrive in a strong economy, fostering growth, stability, and prosperity for individuals.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Immigration pathways: Diverse routes enable individuals to fulfill dreams and contribute to Canada's vibrant society.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                World-class education: Access to top-notch educational institutions empowers individuals and enhances career prospects globally.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Career opportunities: Overflowing job prospects cater to various skills and aspirations, promising fulfilling professional journeys.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Welcoming environment: Inclusive culture embraces diversity, fostering a sense of belonging and acceptance for all.</p>

                            <div class="inqiry_btn" onClick={handleInqurire}>
                                <div className='icon-question-container'>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </div>
                                <span>
                                    Inquire Now
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='advantage-container mt-5 mb-5'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-7 order-md-1 order-2'>
                            <div class="section-title red">
                                <span>Unlocking the Benefits</span>
                            </div>
                            <h2 class="service_details_title mb-4">
                                Advantages of Choosing Go4n Visa
                            </h2>

                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Experienced consultants: Our seasoned consultants are masters of diverse visa application processes worldwide.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Stay updated: Our experts stay current with evolving regulations, ensuring your application aligns with essential requirements for a higher chance of approval.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Guidance through process: Our professionals guide you through the vital process, transforming it into a streamlined and inspiring experience.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Professional application: We ensure your application package radiates professionalism, completeness and a compelling narrative.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Meticulous crafting: We meticulously craft and sculpt your application, ensuring compliance with complex guidelines.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Transform complexity: With our experts, transform a complex process into a harmonious symphony, where aspirations align seamlessly with the logic of approval.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Real-time updates: Stay informed at every step with real-time updates from our dedicated team.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Handle bumps: Our team addresses unexpected bumps with precision and grace, ensuring a smooth, stress-free journey.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Navigate obstacles: We navigate twists and turns, turning obstacles into stepping stones toward your dreams.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Paperwork handled: our experts handle all the paperwork intricacies, allowing you to focus on your dreams.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Maze navigation: Trust us to navigate the maze of rules and regulations, transforming hurdles into stepping stones.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Cost-effective preparation: Our cost-effective services prioritize preparation, saving time, reducing stress, and ensuring a smooth visa experience.</p>
                            <p class="service_details_content">
                                <FontAwesomeIcon icon={faArrowRight} className="text-primary me-2" />
                                Trustworthy passport: Trust us to be your passport to a hassle-free and successful visa application process.</p>

                            <div class="inqiry_btn" onClick={handleInqurire}>
                                <div className='icon-question-container'>
                                    <FontAwesomeIcon icon={faQuestion} />
                                </div>
                                <span>
                                    Inquire Now
                                </span>
                            </div>
                        </div>

                        <div className='col-lg-4 order-md-2 order-1 text-md-end text-center mb-3 mb-md-0'>
                            <img src={Logo} alt='logo' className='logo-immegration' />
                        </div>
                    </div>
                </div>
            </div>


            <div className='explore-content'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-12 text-center">
                            <div className="section-title red text-center">
                                <span>Provinces
                                </span>
                            </div>
                            <h2 className="title-new">
                                Provinces You Can Explore
                            </h2>
                            <p className="content-new">Explore amazing provinces with a helpful guide and discover wonders along the way.
                            </p>
                        </div>

                        <div className="col-12">
                            <Slider {...settings}>
                                {data.map((data, index) => (
                                    <div key={index} className="p-6">
                                        <div className="d-flex align-items-center mb-2 tup-img">
                                            <img src={data.img} alt="testimonial" className="profile_img me-2" />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ImmegrationSection
