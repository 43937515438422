import React from "react";
import uk from "../../assets/img/uk.png";
import australia from "../../assets/img/australia.png"; // Fixed typo in image name
import europe from "../../assets/img/europe.png"; // Fixed typo in image name
import usa from "../../assets/img/usa.png";
import canada from "../../assets/img/canada.png";
import new_zealand from "../../assets/img/new_zealand.png";
import "./studyabroadsection.css";
function StudyAbroadSection() {
  return (
    <div>
      <div className="container-fluid p-5 px-5 study_abroad_main">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12">
            <h1 className="text-primary">Study in United Kingdom</h1>
            <h6 className="text-justify study_text">
              GO4N VISA CONSULTANT is leading the way in overseas education
              consultancy since 2004. With eminence testimonials and trustworthy
              services leads students achieve the best academic height based on
              their academic excellence and fiscal capability. We are helping
              students by offering the best educational institutes and study
              options. In the past, we have recruited many students in
              Australia, Canada, Denmark, EU Countries, New Zealand, USA and UK.
              We are committed to transparency and integrity in our work. We
              recommend the best in their academic pursuit to grab rewarding job
              opportunities at MNCs. We are here to serve you by providing the
              best counseling and guidance to make the right decision which will
              ease your uncertainties and confusion regarding career options.
            </h6>
          </div>
          <div className="col-lg-6 col-md-12">
            <img src={uk} alt="" className="w-100 study_img" />
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 px-5 bg-secondary">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12">
            <img src={usa} alt="" className="w-100 study_img" />
          </div>
          <div className="col-lg-6 col-md-12">
            <h1 className="text-primary">Study in United States</h1>
            <h6 className="text-justify study_text">
              Every year, the number of international students in the USA raises
              as more and more students chooses USA for higher study. Today, the
              USA is most popular country for overseas education. The main
              reasons for choosing the USA by international students attributed
              to academic excellence, quality education and unique curriculum,
              variety of educational opportunities, cutting-edge technology,
              diversity and variety, flexibility, the leading edge, employment
              opportunities, amazing innovation and research etc. USA has been a
              global leader in the area of education, setting standards for the
              quality of faculties, R&D. Its commitment to excellence is
              globally accepted. There is a breadth of knowledge in a diverse
              range of subjects that must be wired into, especially for aspiring
              leaders of the future. The networking experience and the learning
              gained during the time spent in USA institution help you on the
              path towards excellence and eventually success.
            </h6>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 px-5 study_abroad_main">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12">
            <h1 className="text-primary">Study in New Zealand</h1>
            <h6 className="text-justify study_text">
              New Zealand is best known for its stunning countryside, a
              population of about 4.4 million. New Zealand is gradually drawing
              itself towards the center stage of international education. The
              education model adopted New Zealand is fundamentally based on UK
              institutions. The government of New Zealand is now investing huge
              funds in its education sector to increase its presence to attract
              more international students. New Zealand is a model country to
              study overseas because of the international standard education,
              reasonable cost and cosmopolitan culture. Many universities of New
              Zealand are consistently appeared in world ranking of top 500
              universities. Some of the most popular universities of New Zealand
              are University of Auckland, University of Otago, University of
              Canterbury, Victoria University of Wellington, Massey University.
            </h6>
            <h6 className="text-justify mt-4 study_text">
              New Zealand universities strive to offer quality education with
              state-of-the-art facilities, very good student support services
              and safe environment.
            </h6>
          </div>
          <div className="col-lg-6 col-md-12">
            <img src={new_zealand} alt="" className="w-100 study_img" />
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 px-5 bg-secondary">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12">
            <img src={australia} alt="" className="w-100 study_img" />
          </div>
          <div className="col-lg-6 col-md-12">
            <h1 className="text-primary">Study in Australia</h1>
            <h6 className="text-justify study_text">
              Australia is a preferred global education destination with long
              term opportunities. Having excellent infrastructure, practical
              teachings and updated curriculum Australia is attracting more
              international students. Further, Australian government is also
              fully involved in building trustworthiness of education system.
              Additionally, the various ranges of study programs offered by
              Australian educational institutions have resulted in most selected
              country for international students. Many Australian Universities
              are ranked in worldwide university rankings. Due to all these
              factors, students want to pursue study in Australia.{" "}
            </h6>
            <h6 className="text-justify study_text mt-4">
              There are number of Australian Universities and colleges offering
              a variety of courses and degrees to the international students.
              Top Australian Universities include University of Sydney,
              University of Melbourne, University of Queensland, University of
              New South Wales, University of Western Australia and many more.
              The climatic condition and beautiful locations of Australia also
              add up for the choice of international students.
            </h6>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 px-5 study_abroad_main">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12">
            <h1 className="text-primary">Study in Europe</h1>
            <h6 className="text-justify study_text">
            Studying abroad in Europe offers a transformative experience combining academic excellence with cultural immersion. From historic cities steeped in centuries-old architecture to vibrant contemporary art scenes, Europe provides a rich tapestry for academic exploration and personal growth.
Imagine strolling through the cobbled streets of Paris, delving into the intricacies of French literature and art firsthand. Picture yourself in Rome, surrounded by ancient ruins and studying the foundations of Western civilization. From Madrid to Berlin, each city offers unique perspectives on politics, economics, and social dynamics that shape global discourse.
            </h6>
            <h6 className="text-justify study_text">
            Academically, European universities are renowned for their rigorous programs and distinguished faculty members who are often pioneers in their fields. The opportunity to study under such experts and alongside international peers fosters a global outlook and prepares students for an interconnected world.

            </h6>
          </div>
          <div className="col-lg-6 col-md-12">
            <img src={europe} alt="" className="w-100 study_img" />
          </div>
        </div>
      </div>
      <div className="container-fluid p-5 px-5 bg-secondary">
        <div className="row g-5">
          <div className="col-lg-6 col-md-12">
            <img src={canada} alt="" className="w-100 study_img" />
          </div>
          <div className="col-lg-6 col-md-12">
            <h1 className="text-primary">Study in Canada</h1>
            <h6 className="text-justify study_text">
              Canada has constantly maintained its reputation as an attractive
              education focal point due to various reasons like worldwide
              recognition, cost effective, research opportunities and cultural
              exposure. Canada has established itself as immensely high
              benchmark for academic standards. Canada is highest among all
              countries in overall quality of life. Canada is having most
              affordable tuition fees and living cost compared to other
              countries. Many Canadian Universities are recognized in the
              world’s top 200 universities ranking.{" "}
            </h6>
            <h6 className="text-justify study_text mt-4">
              The economy of Canada is one of the wealthiest in the world
              offering a lot of opportunities for lucrative job and business.
              Cross industry partnerships and close-knit relationship with
              educational institutes have been key to draw more investment into
              R&D.
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyAbroadSection;
