import React from 'react'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import InquiryFormSection from '../../components/InquiryFormsSection'

function InquiryForms() {
  return (
    <Layout>
 <PageHeader title={"Inquiry Form"} pageName={"Inquiry"} />
 <InquiryFormSection />
    </Layout>
  )
}

export default InquiryForms