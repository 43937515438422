import React from "react";
import ServiceLayOut from "../../../components/ServicesLayout";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import ticke_visa from "../../../assets/img/ticke_visa.png"
function AirTicketCurrencyExchange() {
  return (
    <Layout>
      <div className="container-fluid bg-secondary px-0">
        <PageHeader
          title={"Air Ticket & Currency Exchange"}
          pageName={"Air Ticket & Currency Exchange"}
        />
        <div className="row g-0">
          <div className="col-lg-9 py-5 px-5">
            <div className='mb-5'>

              <img src={ticke_visa} className='w-100' style={{ aspectRatio: "2/1" }} />
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>INTERNATIONAL AIR TICKETS</strong>
              </h5>
              <p className="text-justify">
                GO4N VISA SOLUTIONS has been committed to providing
                international air tickets with most comprehensive set of
                airlines, fares and flights, with the best competitive fares. We
                have tie-up with Authorized IATA Agency for offering services to
                our valuable clients.
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>FOREIGN CURRENCY EXCHANGE</strong>
              </h5>
              <p className="text-justify">
                We are also dealing with Foreign Currency Exchange i.e. buying
                and selling of US $, GBP, Euro, Aus $, CAD $, NZ $ and other
                currencies.{" "}
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>INTERNATIONAL TRAVEL INSURANCE</strong>
              </h5>
              <p className="text-justify">
                We are associated with insurance companies’ authorized agents
                for extending international travel insurance services for
                students, visitors, business professionals for their foreign
                visit.
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>STUDENT COLLEGE FEE TRANSFER</strong>
              </h5>
              <p className="text-justify">
                We provide hassle-free and efficient student college fee transfer services to ensure that students can focus on their education without worrying about the complexities of international payments. Our dedicated team ensures secure and timely transfers to various educational institutions across the globe.
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>FOREX SUPPORT</strong>
              </h5>
              <p className="text-justify">
                Our Forex support services assist clients in navigating the complexities of foreign exchange transactions. Whether you're traveling abroad or conducting international business, we offer competitive rates and expert guidance to ensure you get the best value for your money.
              </p>
            </div>
          </div>
          <div className="col-lg-3 py-5 service_layout" style={{ minHeight: "400px" }}>
            <ServiceLayOut />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AirTicketCurrencyExchange;
