import React, { useState } from 'react'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import ContactUs from '../../components/ContactUs'

function Contact() {
  const [loaction,setLocation]=useState("Canada Office")
  return (
    <Layout setLocation={setLocation}>
      <PageHeader title={"Contact Us"} pageName={"Contact"} />
      <ContactUs loaction={loaction}/>
    </Layout>
  )
}

export default Contact