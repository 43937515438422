import React from "react";
import Layout from "../../../components/Layout";
import ServiceLayOut from "../../../components/ServicesLayout";
import PageHeader from "../../../components/PageHeader";
import what_we_do from "../../../assets/img/what_we_do.png";
function WhatWeDo() {
  return (
    <Layout>
      <div className="container-fluid bg-secondary px-0">
        <PageHeader title={"What We Do"} pageName={"What We Do"} />

        <div className="row g-0">
          <div className="col-lg-9 py-5 px-5">
            <div className="mb-5">
              <img
                src={what_we_do}
                className="w-100"
                style={{ aspectRatio: "2/1" }}
              />
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>
                  Services provided by GO4N VISA SOLUTIONS to the students:-
                </strong>
              </h5>
              <ul className="text-gray">
                <li>
                  Providing professional counseling and committing to help the
                  students in every step of admission abroad and visa guidance.
                </li>
                <li>
                  Analysis of the student’s interest areas and guiding
                  accordingly.
                </li>
                <li>
                  Providing details of a wide range of courses to select from
                  every field of Engineering, Accounting, Computer Science,
                  Medical, Pharmacy, Physiotherapy, MPH (Masters in Public
                  Health), Nursing, and MBA in 11 streams from various colleges
                  and Universities in U.S.A. / U.K. / Canada.
                </li>
                <li>
                  Providing complete details for undergraduate and graduate
                  admission, duration of course, and list of documents required
                  for admission in the University/College.
                </li>
                <li>
                  Communicating with the International admission staff of the
                  University/College regarding admission and queries for each
                  student individually.
                </li>
                <li>
                  Assisting in drafting Statement of Purpose, Recommendation
                  Letters, Resumes, Applications to Universities, financial
                  documents, etc.
                </li>
                <li>
                  Complete visa guidance for online application forms, preferred
                  visa interview dates, and documentation needed for the
                  interview.
                </li>
                <li>
                  Conducting mock visa interviews at our center until students
                  are satisfied, covering all the latest frequently asked
                  questions and special questions related to particular
                  students.
                </li>
                <li>
                  Increasing the confidence level of the student before they
                  appear for the student visa interview.
                </li>
                <li>
                  Assisting students with airline ticketing once the visa is
                  obtained.
                </li>
                <li>
                  Assisting students in obtaining the best overseas insurance
                  policy during their overseas study.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-lg-3 py-5 service_layout"
            style={{ minHeight: "400px" }}
          >
            <ServiceLayOut />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default WhatWeDo;
