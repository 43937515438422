// import React from "react";
// import "./topbar.css"

// const Topbar = () => {
//   return (
//     <div className="bg-secondary  pe-5 ">
//       <div className="row gx-0">
//         <div className="col-md-8 text-center text-lg-start mb-2 mb-lg-0">
//         <div className="position-relative d-inline-flex align-items-center bg_brown text-white top-shape px-5">
//             <div className="me-3 pe-3 border-end py-2">
//               <p className="m-0"><i className="fa fa-envelope-open me-2"></i>info@example.com</p>
//             </div>
//             <div className="py-2">
//               <p className="m-0"><i className="fa fa-phone-alt me-2"></i>+012 345 6789</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-4 text-center text-lg-end">

//         </div>
//       </div>
//     </div>
//   );
// };

// export default Topbar;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpen,
  faPhoneAlt,
  faMapMarkerAlt,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import "./topbar.css";
import india_flag from "../../assets/icon/india_flag.png";
import canada_flag from "../../assets/icon/canada_flag.png";
import slogan from "../../assets/img/slogan.png"

const Topbar = ({ setLocation }) => {
  const toggleDropdown = (val) => setDropdownOpen(val);
  const [selectValue, setSelectValue] = useState("Canada Office");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen); // Toggle the dropdown
  };

  const handleSelect = (value) => {
    setSelectValue(value);
    if (setLocation) {
      setLocation(value)
    }
    setDropdownOpen(false); // Close dropdown after selection
  };
  return (
    <div className="d-none d-lg-block main-topbar-container">
      <div className="row gx-0">
        <div className="col-md-11 d-flex text-lg-start mb-2 mb-lg-0 ">
          <div className="position-relative h-100 d-inline-flex align-items-center bg_brown text-white top-shape" style={{paddingLeft:"1rem"}}>
            <div className="me-3 pe-3 border-end pt-0">
              <p className="m-0">
                <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                {selectValue === "Canada Office"
                  ? "4-405 Pandora Ave E Winnipeg, Manitoba, Canada R2C 5P3"
                  : "204,Sangath Mall-1, Opp. GTU University, Motera, Ahmedabad"}
              </p>
            </div>
            <div className="me-3 pe-3 border-end pt-0">
              <p className="m-0">
                <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />
                {selectValue === "Canada Office"
                  ? "canadago4n@gmail.com"
                  : "go4nvisa@gmail.com"}
              </p>
            </div>
            <div className="me-0 pe-3 pt-0 border-end ">
              <p className="m-0">
                <FontAwesomeIcon icon={faPhone} className="me-2" />
                {selectValue === "Canada Office"
                  ? "+1-431-345-2611"
                  : "+91-9033435343"}
              </p>
            </div>
          </div>
          {/* <div className="top-shape_view"></div> */}
          <div className="slogan-container">
            <img src={slogan} alt="slogan" />
          </div>
        </div>
        <div className="col-md-1 text-center text-lg-end d-inline-flex align-items-center justify-content-end ">
          {/* <div>
            <label for="touch">
              <span className="top_dropdown">Head Office</span>
            </label>
            <input type="checkbox" id="touch"></input>
            <ul class="slide_deop">
              <li>Canada Office</li>
              <li>India Office</li>
            </ul>
          </div> */}
          {/* <div>
  <label htmlFor="touch">
    <span className="top_dropdown">Head Office</span>
  </label>
  <input type="checkbox" id="touch" className="touch-checkbox" />
  <ul className="slide">
    <li>Canada Office</li>
    <li>India Office</li>
  </ul>
</div> */}
          <div className="topbar_dropdown" style={{zIndex: '10'}}>
            <span className="top_dropdown" onClick={handleDropdownClick}>
              <span className="dp_title">{selectValue}</span>
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </span>
            <ul className={`topbar_slide ${dropdownOpen ? "open" : ""}`}>
              <li onClick={() => handleSelect("Canada Office")}>
                <img
                  src={canada_flag}
                  className="dp_flag_icon"
                  alt="Canada Flag"
                />
                Canada Office
              </li>
              <li onClick={() => handleSelect("India Office")}>
                <img
                  src={india_flag}
                  className="dp_flag_icon"
                  alt="India Flag"
                />
                India Office
              </li>
            </ul>
          </div>
          {/* <div className="topbar_dropdown">
            <span className="top_dropdown">          
              <span className="dp_title">{selectValue}</span>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
            <ul className="topbar_slide">
              <li onClick={() => setSelectValue("Canada Office")}>
                <img src={canada_flag} className="dp_flag_icon" />
                Canada Office
              </li>
              <li onClick={() => setSelectValue("India Office")}>
                <img src={india_flag} className="dp_flag_icon" />
                India Office
              </li>
            </ul>
          </div> */}
          {/* <div
            className="nav-item dropdown bg_brown pt-1 pb-1 "
            onMouseEnter={() => toggleDropdown(true)}
            onMouseLeave={() => toggleDropdown(false)}
          >
            <button
              className="nav-link px-5 dp-btn info-dropdown-toggle bg_brown dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Head Office
            </button>
            <div
              className={`dropdown-menu info-dropdown-menu m-0 ${
                dropdownOpen ? "show" : ""
              }`}
            >
              <div className="dropdown-item info-item">Canada Office</div>
              <div className="dropdown-item info-item">India Office</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
