import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import carousel_1 from '../../assets/img/carousel-1.jpg'; // Update the path accordingly
import carousel_2 from '../../assets/img/carousel-2.jpg'; // Update the path accordingly
import group_111 from '../../assets/img/group_111.png'; 
import group_115 from '../../assets/img/group_115.png'; 
import { Link } from 'react-router-dom';
const carouselData = [
    {
        imgSrc: group_111,
        title: "",
        subtitle: "Professional guidance for planning your study abroad journey.",
    },
    {
        imgSrc: group_115,
        title: "",
        subtitle: "Let us help you reach the highest level of success.",
    },
];

const HeaderCarousel = () => {
    return (
        <div className="container-fluid p-0">
            <Carousel id="header-carousel" className="carousel-fade">
                {carouselData.map((item, index) => (
                    <Carousel.Item key={index} className={index === 0 ? "active" : ""}>
                        <img className="d-block w-100" src={item.imgSrc} alt="Image" />
                        <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3 px-5" style={{ maxWidth: "900px" }}>
                                
                                <h3 className="display-6 text-white mb-md-4 mobile_font">{item.subtitle}</h3>                             
                                <Link
                                    to="/inquiry-forms"
                                    className="btn bg-light py-md-3 px-md-5 rounded-pill"
                                    style={{boxShadow:'0 0 19px #1c1b1b'}}
                                >
                                 <strong>Inquire Now</strong> 
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default HeaderCarousel;
