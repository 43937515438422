// import React from "react";
// import testimonial_1 from "../../assets/img/testimonial-1.jpg";
// import google from "../../assets/img/google.png";
// import "./googlereivew.css";
// import { faStar } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// function GoogleReivewSection() {
//     const data = [{
//         text: "I am totally and very much satisfied with the cooperation and assistance for all the immigration problem and queries. I am overwhelmed with your personal assistance and care with which you handled my case. It was like speaking to a friend rather than a professional; you guys are very approachable and friendly. I am so thankful and delighted that I had such a sincere and hard working representative to take care of the things for mine. All our future family visa applications will be handles by Go4n Visa.",
//         img: testimonial_1,
//         name: "Kiran Thakkar",
//     }, {
//         text: "I am 12th Pass and used to assist my father in his business. I applied for WHM visa through Go4n Visa and today I am enjoying holiday as well as working in the UK for the last more than one year. I never knew it was possible for a person with very limited English skills to live and work legally in the UK on a Two Year visa. It is only through Go4n Visa quality file processing that someone with my kind of profile is also able to get a visa for the UK. I wholeheartedly recommend the Go4n Visa for all your immigration needs",
//         img: testimonial_1,
//         name: "Mayur Patel",
//     },
//     {
//         text: "I am totally and very much satisfied with the cooperation and assistance for all the immigration problem and queries. I am overwhelmed with your personal assistance and care with which you handled my case. It was like speaking to a friend rather than a professional; you guys are very approachable and friendly. I am so thankful and delighted that I had such a sincere and hard working representative to take care of the things for mine. All our future family visa applications will be handles by Go4n Visa.",
//         img: testimonial_1,
//         name: "Sandip Bhavsar",
//     },
//     {
//         text:                         "Right from the word 'GO' Go4n Visa was so professional and friendly and we found the service extremely reliable. Mukesh Patel himself was very efficient and organised, which made the process of applying for our student visa a lot less stressful. He kept us regularly updated through emails and phone calls on what needed to be done and helped us with any queries we had about form filling in, to evidence we needed to support our application.",
//         img: testimonial_1,
//         name: "Kiran Thakkar",
//     },
//     {
//         text:  "I am impressed! Excellent job! I find my visitor visa process with the assistance of Go4n Visa so much easier. I consider such price/quality ratio to be the best in the market. All questions I had during the process were promptly addressed and resolved. I am absolutely satisfied with service of Go4n Visa and would highly recommend Go4n Visa to all potential clients. Thank you once again, guys! It's always a pleasure to deal with professionals!",
//         img: testimonial_1,
//         name: "Kanubhai Patel",
//     },
//     {
//         text: "It's been pleasure working with you. I think your company is among the best in the state of Gujarat. I first had your help with my H1B application. Your excellent service and high efficiency made me come back to start my green card application. After my labour certification is approved, I came back again for I140 and 485 processes. You always provide high quality client services and expertise. Whether by email or phone, your communication is always clear and organized. Whenever I have questions, I feel you are very accountable to turn to. I have recommended your company to my friends and will continue to do so. I hope your company will grow bigger so that more people can benefit from your great services.",
//         img: testimonial_1,
//         name: "Dipesh Patel",
//     },
//     ]
//   return (
//     <div className="container-fluid py-6 px-5">
//       <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
//         <h1 className="display-5 mb-0">What Our Client Say?</h1>
//         <hr className="w-25 mx-auto bg-primary" />
//       </div>
//       <div className="p-6">
//         <div className="bg-secondary p-3 review_box">
//           <div className="d-flex align-items-center space_between">
//             <img src={testimonial_1} className="profile_img" />
//             <h6>Kiran Thakkar</h6>
//             <img src={google} className="google_icon" />
//           </div>
//           <div className="py-1 d-flex align-items-center">
//             <FontAwesomeIcon icon={faStar} className="star_icon" />
//             <FontAwesomeIcon icon={faStar} className="star_icon" />
//             <FontAwesomeIcon icon={faStar} className="star_icon" />
//             <FontAwesomeIcon icon={faStar} className="star_icon" />
//             <FontAwesomeIcon icon={faStar} className="star_icon" />
//           </div>
//           <div>
//             <p className="fs-6 fw-normal mb-4">I am totally and very much satisfied with the cooperation and assistance for all the immigration problem and queries. I am overwhelmed with your personal assistance and care with which you handled my case. It was like speaking to a friend rather than a professional; you guys are very approachable and friendly. I am so thankful and delighted that I had such a sincere and hard working representative to take care of the things for mine. All our future family visa applications will be handles by Go4n Visa.</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default GoogleReivewSection;


import React from "react";
import testimonial_1 from "../../assets/img/testimonial-1.jpg";
import google from "../../assets/img/google.png";
import "./googlereivew.css";
import { faArrowLeft, faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";

function GoogleReivewSection() {
    const PrevArrow = ({ onClick }) => {
        return (
            <div className="reivew-custom-arrow reivew-prev-arrow " onClick={onClick}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </div>
        );
    };
    
    // Custom Next Arrow
    const NextArrow = ({ onClick }) => {
        return (
            <div className="reivew-custom-arrow reivew-next-arrow" onClick={onClick}>
                <FontAwesomeIcon icon={faArrowRight} />
            </div>
        );
    };
    
//   const data = [
//     {
//       text: "I am totally and very much satisfied with the cooperation and assistance for all the immigration problem and queries. I am overwhelmed with your personal assistance and care with which you handled my case. It was like speaking to a friend rather than a professional; you guys are very approachable and friendly. I am so thankful and delighted that I had such a sincere and hard working representative to take care of the things for mine. All our future family visa applications will be handles by Go4n Visa.",
//       img: testimonial_1,
//       name: "Kiran Thakkar",
//     },
//     {
//       text: "I am 12th Pass and used to assist my father in his business. I applied for WHM visa through Go4n Visa and today I am enjoying holiday as well as working in the UK for the last more than one year. I never knew it was possible for a person with very limited English skills to live and work legally in the UK on a Two Year visa. It is only through Go4n Visa quality file processing that someone with my kind of profile is also able to get a visa for the UK. I wholeheartedly recommend the Go4n Visa for all your immigration needs.",
//       img: testimonial_1,
//       name: "Mayur Patel",
//     },
//     {
//       text: "I am totally and very much satisfied with the cooperation and assistance for all the immigration problem and queries. I am overwhelmed with your personal assistance and care with which you handled my case. It was like speaking to a friend rather than a professional; you guys are very approachable and friendly. I am so thankful and delighted that I had such a sincere and hard working representative to take care of the things for mine. All our future family visa applications will be handles by Go4n Visa.",
//       img: testimonial_1,
//       name: "Sandip Bhavsar",
//     },
//     {
//       text: "Right from the word 'GO' Go4n Visa was so professional and friendly and we found the service extremely reliable. Mukesh Patel himself was very efficient and organised, which made the process of applying for our student visa a lot less stressful. He kept us regularly updated through emails and phone calls on what needed to be done and helped us with any queries we had about form filling in, to evidence we needed to support our application.",
//       img: testimonial_1,
//       name: "Kiran Thakkar",
//     },
//     {
//       text: "I am impressed! Excellent job! I find my visitor visa process with the assistance of Go4n Visa so much easier. I consider such price/quality ratio to be the best in the market. All questions I had during the process were promptly addressed and resolved. I am absolutely satisfied with service of Go4n Visa and would highly recommend Go4n Visa to all potential clients. Thank you once again, guys! It's always a pleasure to deal with professionals!",
//       img: testimonial_1,
//       name: "Kanubhai Patel",
//     },
//     {
//       text: "It's been pleasure working with you. I think your company is among the best in the state of Gujarat. I first had your help with my H1B application. Your excellent service and high efficiency made me come back to start my green card application. After my labour certification is approved, I came back again for I140 and 485 processes. You always provide high quality client services and expertise. Whether by email or phone, your communication is always clear and organized. Whenever I have questions, I feel you are very accountable to turn to. I have recommended your company to my friends and will continue to do so. I hope your company will grow bigger so that more people can benefit from your great services.",
//       img: testimonial_1,
//       name: "Dipesh Patel",
//     },
//   ];
const data = [
    {
      text: "I am very satisfied with Go4n Visa's assistance for my immigration queries. Their personal care made the process feel friendly, and I will choose them for all future family visa applications.",
      img: testimonial_1,
      name: "Kiran Thakkar",
    },
    {
      text: "As a 12th Pass assisting my father's business, I applied for a WHM visa through Go4n Visa and have been living in the UK for over a year. Their excellent processing helped me get a visa despite my limited English skills. I highly recommend them for immigration needs.",
      img: testimonial_1,
      name: "Mayur Patel",
    },
    {
      text: "I appreciate Go4n Visa's friendly approach in handling my immigration queries, making the process easier. I will choose them for future applications.",
      img: testimonial_1,
      name: "Sandip Bhavsar",
    },
    {
      text: "Go4n Visa was professional and friendly from the start, making our student visa application much less stressful. Mukesh Patel was efficient and kept us well-informed.",
      img: testimonial_1,
      name: "Kiran Thakkar",
    },
    {
      text: "I'm impressed with Go4n Visa! The visitor visa process was seamless, and their service quality is top-notch. I highly recommend them for immigration assistance.",
      img: testimonial_1,
      name: "Kanubhai Patel",
    },
    {
      text: "Working with Go4n Visa has been a pleasure. After their excellent service with my H1B application, I returned for my green card process and confidently recommend them to others.",
      img: testimonial_1,
      name: "Dipesh Patel",
    },
  ];
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default number of slides for larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1025, // Screens smaller than 1024px
        settings: {
          slidesToShow: 3, // Show 2 slides on medium-sized screens
        },
      },
      {
        breakpoint: 768, // Screens smaller than 768px
        settings: {
          slidesToShow: 2, // Show 1 slide on small screens
        },
      },
      {
        breakpoint: 480, // Screens smaller than 480px
        settings: {
          slidesToShow: 1, // Show 1 slide on extra-small screens
          arrows: false, // Optionally hide arrows for mobile view
        },
      },
    ],
  };
  return (
    <div className="container-fluid py-6 px-5">
      <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
        <h1 className="display-5 mb-0">What Our Clients Say?</h1>
        <hr className="w-25 mx-auto bg-primary" />
      </div>
      <Slider {...settings}>
        {data.map((review, index) => (
          <div key={index} className="p-6">
            <div className="bg-secondary p-3 review_box shadow">
              <div className="d-flex align-items-center mb-2">
                <img src={review.img} alt="testimonial" className="profile_img me-2" />
                <div>
                <h6 className="title_name mb-0">{review.name}</h6>
                <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faStar} className="star_icon" />
                <FontAwesomeIcon icon={faStar} className="star_icon" />
                <FontAwesomeIcon icon={faStar} className="star_icon" />
                <FontAwesomeIcon icon={faStar} className="star_icon" />
                <FontAwesomeIcon icon={faStar} className="star_icon" />
              </div>
                </div>
              </div>
              <p className="fs-6 fw-normal mb-4" style={{fontSize:"16px"}}>{review.text}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default GoogleReivewSection;
