import React from "react";
import group_124 from "../../assets/img/group_124.png";
import { Link } from "react-router-dom";
import "./appointment.css";
function AppointmentSection() {
  return (
    <div
      className=" bg_light_green text-secondary py-5 "
      style={{
        backgroundImage: `url(${group_124})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
      }}
    >
      <div className="d-flex flex-direction-column align-items-center justify-content-center">
      <div>
        <div className="text-center py-2 ">
          <h1 className="display-1 text_light mb-2">Make An Appointment</h1>
          <h5 className="text-white mb-4">
            Schedule a complimentary consultation with our migration experts
            today
          </h5>
          <Link to={"/contact"} className="shadow">
          <button
            className="btn_brown p-2 px-4 shadow"
            style={{ fontSize: "20px", fontWeight: "700" }}
          >
           
            Meet Our Experts
      
          </button>
          </Link>
        </div>
      </div>
      </div>
   
    </div>
  );
}

export default AppointmentSection;
