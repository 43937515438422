import React from "react";
import ServiceLayOut from "../../../components/ServicesLayout";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import overseas_visa from "../../../assets/img/overseas_visa.png"
function OverseasPlacement() {
  return (
    <Layout>
      <div className="container-fluid bg-secondary px-0">
      <PageHeader title={"Overseas Placement"} pageName={"Overseas Placement"} />

        <div className="row g-0">
          <div className="col-lg-9 py-5 px-5">
          <div className='mb-5'>

<img src={overseas_visa} className='w-100' style={{aspectRatio:"2/1"}}/>
</div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>About Us</strong>
              </h5>
              <p className="text-justify">
                We are one of the leading overseas recruitment consultants and
                working as Associate of many companies based in Australia,
                Canada, USA, Middle East and EU countries. We cater to Health &
                Health Care and Hospitality Sector providing satisfactory
                placement services. We recruit for entry level to senior
                positions. We also strive to know the expectations of our
                recruiters and job seekers. We effort to build an in-depth
                understanding of your goal to meet your needs effectively.
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Our Mission:</strong>
              </h5>
              <p className="text-justify">
                To help our customer source and hire the right candidate for the
                right job in right time and to help professionals to find best
                job opportunities in their fields in most transparent way
                following ethical standards.{" "}
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Our Business Approach:</strong>
              </h5>
              <p className="text-justify">
                Our efficient recruitment method helps us to cater to the
                requirement of professional manpower as per the needs of the
                clients. Our qualified and committed staff provides their
                valuable contribution in screening, interviewing and assessment
                needs with their knowledge and proficiency, which facilitate us
                to handle clients’ requirement competently and swiftly.{" "}
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Why Us?</strong>
              </h5>
              <p className="text-justify">
                We pay careful consideration during the selection and
                recruitment process and ensure that the professionals recruited
                by us have a prolific and affirmative approach. We also make
                sure that the recruitments are tailor made to precisely fit
                clients’ hiring needs and financial concerns. Some of salient
                features listed below:
              </p>
              <ul className="text-gray">
                <li>Committed to provide value added services</li>
                <li>Progressive service support by our professional team</li>
                <li>Good database of experienced candidates</li>
                <li>Providing most talented professionals</li>
                <li>
                  Providing required training to the candidates by our experts
                </li>
              </ul>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Our Infrastructure Facilities:</strong>
              </h5>
              <p className="text-justify">
                We have excellent infrastructure facilities comprise of modern
                office with latest communication equipment, conference room,
                training center with latest software for quick expedition of
                whole process.
              </p>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Services for Recruiters:</strong>
              </h5>
              <ul className="text-gray">
                <li>
                  Having excellent database of job aspirants of all levels
                </li>
                <li>Deep understanding of clients’ requirements</li>
                <li>Up dated knowledge of existing market</li>
                <li>Providing high level of satisfactory services</li>
                <li>Affordable service rates</li>
              </ul>
            </div>
            <div>
              <h5 className="mb-4 text-gray">
                <strong>Services for Candidates:</strong>
              </h5>
              <ul className="text-gray">
                <li>Providing Immigration and Passport Services </li>
                <li>Visa Process, Visa Assistance Services
                </li>
                <li>Documentation</li>
                <li>Offering lucrative job opportunities</li>
                <li>Providing training for interview and counseling
                </li>
                <li>Attestation Ticketing
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 py-5 service_layout" style={{ minHeight: "400px" }}>
            <ServiceLayOut />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default OverseasPlacement;
