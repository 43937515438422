import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';

const ContactUs = ({ loaction }) => {
  const [link, setLink] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);  // New state for tracking submission

  useEffect(() => {
    const newLink = loaction === "India Office"
      ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14679.187401947444!2d72.57878340382527!3d23.104531364436344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83c94ad291dd%3A0x78ab6c25d03474d1!2sSangath%20Mall-1%2C%20Chandkheda%2C%20Ahmedabad%2C%20Gujarat%20382424!5e0!3m2!1sen!2sin!4v1727250219450!5m2!1sen!2sin"
      : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2570.274968279101!2d-97.01536022460736!3d49.8936408273002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52ea7b68dbc7de27%3A0x59047d820cec140e!2s405%20Pandora%20Ave%20W%20%234%2C%20Winnipeg%2C%20MB%20R2C%201N1%2C%20Canada!5e0!3m2!1sen!2sin!4v1727249884821!5m2!1sen!2sin";

    setLink(newLink);
  }, [loaction]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);  // Disable button and show loader
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}/api/add-contacts`, formData);
        // const response = await axios.post("http://localhost:8000/api/add-contacts", formData);
        toast.success(response.data.message);

        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        if (error.response) {
          console.error("Validation Error Response:", error.response.data);
        } else {
          console.error("API Error:", error);
        }
        setErrors({
          apiError: error.response?.data?.message || "An error occurred. Please try again.",
        });
      } finally {
        setIsSubmitting(false);  // Re-enable button after submission is done
      }
    }
  };

  return (
    <div className="container-fluid bg-secondary px-0">
      <div className="row g-0">
        <div className="col-lg-6 py-6 px-5">
          <h1 className="display-5 mb-4">Contact For Any Queries</h1>
          <form onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-6 col-xs-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder='Full Name'
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                  <label htmlFor="form-floating-1">
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                    Full Name
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-floating">
                  <input
                    type="email"
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='email'
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                  <label htmlFor="form-floating-2">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    Email Address
                  </label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.subject ? "is-invalid" : ""}`}
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder='subject'
                  />
                  {errors.subject && (
                    <div className="invalid-feedback">{errors.subject}</div>
                  )}
                  <label htmlFor="form-floating-3">
                    <FontAwesomeIcon icon={faCommentDots} className="me-2" />
                    Subject
                  </label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <textarea
                    className={`form-control ${errors.message ? "is-invalid" : ""}`}
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    style={{ height: '150px' }}
                    placeholder="Message"
                  />
                  {errors.message && (
                    <div className="invalid-feedback">{errors.message}</div>
                  )}
                  <label htmlFor="form-floating-4">Message</label>
                </div>
              </div>
              <div className="col-12">
                <button
                  className="btn btn-primary w-100 py-3"
                  type="submit"
                  disabled={isSubmitting}  // Disable when submitting
                >
                  {isSubmitting ? (
                    <span>
                      <i className="fa fa-spinner fa-spin me-2"></i> Submitting...
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-6" style={{ minHeight: '400px' }}>
          <div className="position-relative h-100">
            <iframe
              className="position-relative w-100 h-100"
              src={link}
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
