import React from 'react'
import whatsappicon from "../../assets/img/whatsapp-img.png"
function WhatsAppIcon
() {
  return (
    <a href='https://wa.me/+919825060756' target='_blank'      className="btn btn-lg  btn-lg-square rounded-circle whatsapp_icon d-flex align-items-center justify-content-center"
>
  <img src={whatsappicon} />
  
   </a>
  )
}

export default WhatsAppIcon
