import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { faAnglesRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import bg_img from "../../assets/img/bg_img.png"
const PageHeader = ({title,pageName}) => (
  <div className="container-fluid bg-dark p-5"
  style={{
    backgroundImage: `url(${bg_img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  }}>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="display-4 text-light">{title}</h1>
        <Link to="/" className=' text-white '>Home</Link>
        {/* <FontAwesomeIcon icon={faSquare} className="text-primary px-2" /> */}
        <FontAwesomeIcon icon={faAnglesRight} className="text-light px-2  text-white " />
        <a href="" className=' text-white '>{pageName}</a>
      </div>
    </div>
  </div>
);

export default PageHeader;
