import React from "react";
import temp_part from "../../assets/img/temp_part.png";
function PartnerSection() {
  return (
    <div className="container-fluid p-5 px-5">
      <div className="row g-5">
        <div className="col-lg-6 col-md-8">
          <div>
            <h1>Partnered with 40+ Colleges and Institutes</h1>
            <p style={{ textAlign: "justify" }}>
              <span>
                At Go4n Visa, our commitment to excellence is exemplified by our
                expansive network of over 40 trusted partners spanning the
                globe. Through strategic collaborations with leading agencies,
                seasoned immigration experts, and governmental bodies, we ensure
                a robust infrastructure to facilitate seamless visa processing
                for clients seeking travel documentation across diverse
                international destinations. Our dedicated team of professionals
                brings unparalleled expertise, navigating the intricate nuances
                of global travel regulations and procedures with precision and
                efficiency. Rest assured, whether you’re embarking on a journey
                for business, education, or leisure, our comprehensive network
                and specialized support guarantee a smooth, secure, and
                hassle-free visa application experience from start to finish.
              </span>
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-8">
          <img
            src={temp_part}
            style={{ width: "100%", aspectRatio: 1.8 / 1 }}
          />
        </div>
      </div>
    </div>
  );
}

export default PartnerSection;
