import React from "react";
import Layout from "../../components/Layout";
import { Carousel, Button } from "react-bootstrap";
import carousel_1 from "../../assets/img/carousel-1.jpg";
import carousel_2 from "../../assets/img/carousel-2.jpg";
import feature from "../../assets/img/feature.jpg";
import quote from "../../assets/img/quote.jpg";
import team_1 from "../../assets/img/team-1.jpg";
import team_2 from "../../assets/img/team-2.jpg";
import team_3 from "../../assets/img/team-3.jpg";
import blog_1 from "../../assets/img/blog-1.jpg";
import blog_2 from "../../assets/img/blog-2.jpg";
import blog_3 from "../../assets/img/blog-3.jpg";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  faUserTie,
  faChartPie,
  faChartLine,
  faChartArea,
  faBalanceScale,
  faHouseDamage,
  faQuoteLeft,
  faBlog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderCarousel from "../../components/HeaderCarousel";
import AboutSection from "../../components/AboutSection";
import ServicesSection from "../../components/ServiceSection";
import FeaturesSection from "../../components/FeaturesSection";
import QuoteSection from "../../components/QuoteSection";
import TeamSection from "../../components/TeamSection";
import BlogSection from "../../components/BlogSection";
import TestimonialSection from "../../components/TestimonialSection";
import GoogleReivewSection from "../../components/GoogleReviewSection";
import AppoitmentSection from "../../components/AppointmentSection";
import PartnerSection from "../../components/PartnerSecrion";
function Home() {
  return (
    <Layout>
      {/* Carousel */}
      {/* <div className="container-fluid p-0">
        <Carousel id="header-carousel" className="carousel-fade">
          <Carousel.Item className="active">
            <img className="d-block w-100" src={carousel_1} alt="Image" />
            <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h5 className="text-white text-uppercase">
                  Business Consultancy
                </h5>
                <h1 className="display-1 text-white mb-md-4">
                  We Provide Solution On Your Business
                </h1>
                <Button
                  href=""
                  className="btn btn-primary py-md-3 px-md-5 me-3 rounded-pill"
                >
                  Get Quote
                </Button>
                <Button
                  href=""
                  className="btn btn-secondary py-md-3 px-md-5 rounded-pill"
                >
                  Contact Us
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={carousel_2} alt="Image" />
            <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h5 className="text-white text-uppercase">
                  Business Consultancy
                </h5>
                <h1 className="display-1 text-white mb-md-4">
                  Take Our Help To Reach The Top Level
                </h1>
                <Button
                  href=""
                  className="btn btn-primary py-md-3 px-md-5 me-3 rounded-pill"
                >
                  Get Quote
                </Button>
                <Button
                  href=""
                  className="btn btn-secondary py-md-3 px-md-5 rounded-pill"
                >
                  Contact Us
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div> */}
      <HeaderCarousel />   
      {/* Services */}
      <ServicesSection />
      {/* Features */}
      <FeaturesSection />

      {/* What Our Client Say */}
      <GoogleReivewSection />
   {/* AppoitmentSection */}
      <AppoitmentSection />
   {/* Partner section */}
   <PartnerSection />
    </Layout>
  );
}

export default Home;
