import React from 'react';
import Topbar from '../TopBar';
import Header from '../Header';
import Footer from '../Footer';
import BackToTopButton from '../BackToTop';
import WhatsAppIcon from '../Whatsappicon';



const Layout = ({setLocation, children }) => {
  return (
    <>
      {/* Topbar */}
      <Topbar setLocation={setLocation}/>
      
      {/* Header */}
      <Header />
      
      {/* Main Content */}
      <main>
        {children}
      </main>
      <BackToTopButton/>
      <WhatsAppIcon/>
      {/* Footer */}
      <Footer />
    </>
  );
};

export default Layout;
